.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-body {
  background-color: rgb(6, 16, 65);
  background-image: linear-gradient(#003a4a, #0092bc);
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.Loader-body {
  background-color: #282c34;
  background-image: linear-gradient(#003a4a, #0092bc);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

}

.App-link {
  color: #61dafb;
}

#inputID::placeholder {
  color: #070606;
  align-content:right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.button {
        background-color: #1c87c9;
        -webkit-border-radius: 60px;
        border-radius: 60px;
        border: none;
        color: #eeeeee;
        cursor: pointer;
        display: inline-block;
        font-family: sans-serif;
        font-size: 20px;
        padding: 5px 15px;
        text-align: center;
        text-decoration: none;
      }
    @keyframes glowing {
      0% {
        background-color: #1505a8;
        box-shadow: 0 0 5px #1505a8;
      }
      50% {
        background-color: #195ee8;
        box-shadow: 0 0 20px #195ee8;
      }
      100% {
        background-color: #1505a8;
        box-shadow: 0 0 5px #1505a8;
      }
    }
  .button {
    animation: App-logo-spin 2000ms infinite;
  }

.logo{
  /*animation: glowing 2000ms infinite;*/
  height: 40vmin;

}
.txtglo{
  animation: glowing 2000ms infinite;

}